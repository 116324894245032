@import "../../global_styling/variables.scss";

.chrome__notifier {
	background: $white_color--dark;
	width: 30%;
	height: auto;
	padding: 20px;
	min-width: 400px;
	text-align: center;
	margin: auto;
	margin-top: 50px;
	justify-content: center;
}
.username-selector {
	&__container {
		background: $white_color--dark;
		width: 30%;
		height: auto;
		padding: 20px;
		min-width: 400px;
		text-align: center;
		margin: auto;
		margin-top: 50px;
		justify-content: center;
	}
	&__input_container {
		display: flex;
	}
	&__button {
		background-color: $pink_color--dark;
		color: $whitesmoke--dark;
		margin: "20px";
		padding-left: 12px;
	}

	&__label {
		margin: 0;
		font-weight: bold;
		padding-right: 50px;
	}

	&__preview_video_wrapper {
		display: flex;
		justify-content: center;
		padding-top: 40px;
	}

	&__preview_video {
		width: 60%;
		height: 30%;
	}
}

.video_call_screen {
	&__invitation_link {
		&__container {
			height: 100vh;
			width: 100vw;
			max-width: 100vw;
			max-height: 100vh;

			align-items: center;
			justify-content: center;
			text-align: center;
		}

		&__invite_button {
			background-color: $pink_color--dark;
			color: $whitesmoke--dark;
			margin: 10px inherit inherit 20px;
			width: 120px;
			font-size: 10px;
		}
	}

	&__video_stream_container {
		height: calc(100% - 150px);
		width: 100%;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}

	&__video_stream {
		flex-grow: 1;
		flex-shrink: 1;
		min-width: 20vw;
		> video {
			margin: 10px;
			width: 100%;
			height: 100%;
			max-height: 100%;
		}
	}

	&__call_buttons {
		z-index: 2;
		position: fixed;
		bottom: 0px;
		width: 100%;
		height: auto;
		background-color: whitesmoke;
		color: whitesmoke;
		text-align: center;
		&--webcam-icon {
			color: #424242;
		}
		&--mic-icon {
			color: #424242;
		}
		&--endcall-icon {
			color: #f44336;
		}
		&--screenshare-icon {
			color: #424242;
		}
		&--chat-icon {
			color: #424242;
		}
	}

	&__chat_modal {
		z-index: 999999;
		&__body {
			overflow: auto;
			overflow-y: auto;
			height: 400px;
			text-align: left;
		}

		&__footer {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
		}

		&__send_button {
			background-color: $pink_color--dark;
		}
	}
}
