@import "../../global_styling/variables.scss";

.home_page {
	&__container {
		overflow: auto;
		height: 100vh;
		width: 100vw;
		background-color: $background_color--dark;
		background-image: url("/images/background.png");
		background-repeat: no-repeat;
		background-size: cover;
	}

	&__input_box {
		color: #fff;
	}
	&__landing_page_image {
		max-height: 500px;
		margin: 0 auto;
		width: auto;
		border-radius: 10%;
	}

	&__heading_grid {
		display: block;
		gap: 2rem;
		justify-content: space-between;
		margin-bottom: 2rem;
		text-align: center;
		color: $white_color--dark;
	}

	&__heading {
		font-size: 3rem;
	}
	&__heading_sm {
		font-size: 1rem;
		margin: auto 0.5rem;
	}
	&__heading_2 {
		font-size: 2rem;
		line-height: 1.4rem;
	}

	&__sub-heading {
		display: flex;
		justify-content: center;
		flex-direction: column;
		line-height: initial;
		font-size: 1.5rem;
		color: $heading_color--dark;
		text-align: center;
	}

	&__url_container {
		align-items: center;
		display: block;
	}

	&__start_text {
		color: $white_color--dark;
		font-size: 1.2rem;
		text-align: center;
		margin-top: 1rem;
	}

	&__join_button {
		padding: 1rem;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
			Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
		background-color: $pink_color--dark;
		margin-top: 1rem;
	}

	@media screen and (min-width: 800px) {
		&__heading {
			font-size: 4rem;
		}
		&__heading_2 {
			font-size: 3rem;
		}
		&__heading_sm {
			font-size: 1.5rem;
		}
		&__sub-heading {
			font-size: 2rem;
		}
	}

	@media screen and (min-width: 1200px) {
		&__heading_grid {
			display: flex;
		}
		&__sub-heading {
			text-align: right;
		}
		&__heading_grid {
			text-align: inherit;
		}
		&__heading {
			font-size: 6rem;
		}
		&__heading_2 {
			font-size: 4rem;
		}
		&__heading_sm {
			font-size: 2rem;
		}
		&__sub-heading {
			font-size: 3rem;
			line-height: 3.2rem;
		}
		&__url_container {
			display: flex;
		}
		&__start_text {
			text-align: initial;
			font-size: 1.5rem;
			margin-top: 0;
		}
	}
}
