@import "../../global_styling/variables.scss";
.navbar {
	&__container {
		width: 100%;
		height: 10%;
		padding: 20px;
	}

	&__nav-item {
		color: $heading_color--dark;
		font-size: 22px;
		padding: 4px;
		font-weight: 300;
	}

	&__heading {
		/*this should be below the nav item as it is overriding it*/
		color: $white_color--dark;
		font-weight: 400;
	}

	&__button {
		border-radius: 30%;
		width: 10vw;
		padding: 10px;

		&--dark {
			background-color: $button_color--dark;
		}
		&--pink {
			background-color: $pink_color--dark;
		}
	}
}
