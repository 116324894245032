html,
body {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
}
body {
	background-color: whitesmoke;
}

#root {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
}

* {
	font-family: "Josefin Sans";
}
